<template>
  <section class="sideone position-relative">
    <v-row style="align-items: center" justify="space-between">
      <v-col cols="12" md="6" class="px-0">
        <v-img :src="icon" class="pa-3 bg-sec">
          <img :src="logo" alt="" width="150"/>
          
          <div
            class="sec-login"
            style="
              width: 400px;
              margin: auto;
              height: 50%;
              display: grid;
              place-items: center start;
            "
          >
            <div style="width: 100%">
              <h3 class="mb-3">
                {{ $t('LogIn') }}
                </h3>
              <v-text-field
                type="text"
                variant="solo"
                :placeholder="$t('phone_number')+' / '+$t('email')"
                class="mb-5 tel"
                v-model="loginForm.phone"
                hide-details
              ></v-text-field>
             
              <div class="text-red mb-4">
                {{ errorMessage ? errorMessage : "" }}
              </div>
              <!-- <v-btn v-bind="props" text="Open Dialog"> </v-btn> -->
              <v-btn
                width="100%"
                height="50px"
                class="text-white"
                color="#41D3BD"

                style="box-shadow: rgb(77 71 195 / 20%) 0px 2px 16px 0px"
                @click="handleLogin"
              >
                {{ $t('LogIn') }}
              </v-btn>
              <!-- <textotp/> -->
              <v-dialog width="500" v-model="openotp">
                <v-card
                  class="py-11 px-8 text-center ma-0 over"
                  elevation="12"
                  max-width="500"
                  width="100%"
                  v-if="otpmessage"
                >
                  <div style="font-size: 19px; font-weight: 500">
                    {{ otpmessage }}
                    {{ $t('OTP_phone') }}
                  </div>

                  <v-sheet color="surface">
                    <div class="otp-inputs d-flex justify-center align-center mt-5">
    
<v-text-field
  v-for="(input, index) in otpInputs"
  :key="index"
  v-model="otpInputs[index]"
  :input-attrs="{ inputmode: index === otpInputs.length - 1 ? 'text' : 'numeric', maxlength: 1 }"
  @input="handleInput($event, index)"
  outlined
  dense
  class="otp-field"
  ref="otpInput"
  hide-details
  variant="solo"
  :type="index === otpInputs.length - 1 ? 'text' : 'tel'"
  maxlength="1"
></v-text-field>


      <!-- <v-text-field
        v-for="(input, index) in otpInputs"
        :key="index"
        v-model="otpInputs[index]"
        :input-attrs="{ inputmode: 'numeric', maxlength: 1, pattern: '\\d*' }"
        @input="handleInput(index)"
        outlined
        dense
        class="otp-field"
        ref="otpInput"
        hide-details
        variant="solo"
        type="tel"
      ></v-text-field> -->
    </div>
                    <!-- <v-otp-input
                      v-model="otp"
                      length="4"
                      type="tel"
                      variant="solo"
                      inputmode="number"

                    ></v-otp-input> -->
                  </v-sheet>
                  <div class="text-red my-4">
                {{ errorMessage ? errorMessage : "" }}
              </div>
                  <v-btn
                    class="my-4 mx-auto"
                    color="#252a31"
                    height="40"
                    :text="$t('Verify')"
                    variant="flat"
                    width="50%"
                    @click="handleverify"
                  ></v-btn>
                </v-card>
              </v-dialog>
              <div class="text-center pt-2">
                {{ $t('Don_account') }}  
                <p
                  class="d-inline"
                  style="color: #0e166833; cursor: pointer"
                  @click="signlink"
                >
                  {{ $t('Create_account') }} 
                </p>
              </div>
            </div>
          </div>
          <div class="w-100 d-flex justify-end arrowimg">
            <img :src="arrow" alt="" />
          </div>
        </v-img>
      </v-col>
      <div
        class="position-absolute logimg"
        style="right: 40%; top: 25px; width: 280px; z-index: 111"
      >
        <v-img :src="Vect" >
          <div
            class="d-flex justify-center align-center w-100 h-100"
            style="font-size: 28px; font-weight: 500"
          >
            <p class="text-center">{{ $t('LogIn') }} </p>
          </div>
        </v-img>
        <!-- <img :src="Vect"  alt="" /> -->
      </div>

      <v-col cols="12" md="5" class="px-0">
        <v-img :src="Loginamico" class="imgsection"> </v-img>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import logo from "../../../assets/image/logo.png";
import image from "../../../assets/image/Vector.png";
import Loginamico from "../../../assets/image/Loginamico.svg";
import Vect from "../../../assets/image/Vect.png";
import arrow from "../../../assets/image/Grouparrow.png";
import OTP from "./OTP.vue";
// import textotp from "./textotp.vue";
import CrudDataService from "@/Service/CrudDataService";
import { nextTick } from "vue"; // Import nextTick

export default {
  data() {
    return {
      otp: "",
      logo: logo,
      icon: image,
      Loginamico: Loginamico,
      Vect: Vect,
      arrow: arrow,
      loginForm: {
        phone: "",
      },
      invalidPhoneNumber: false,
      validationMessage: "",
      errorMessage: "",
      otpmessage: "",
      openotp: false,
      otpInputs: ['', '', '', ''], // Array to hold the values of each input

    };
  },
  components: {
    OTP,
    
  },
  methods: {
    handleInput(event, index) {
      const key = event.key;
      let value = event.target.value;

      // If it's the last input, allow numbers & letters
      if (index === this.otpInputs.length - 1) {
        this.otpInputs[index] = value.replace(/[^a-zA-Z0-9]/g, ""); // Alphanumeric
      } else {
        this.otpInputs[index] = value.replace(/[^0-9]/g, ""); // Only numbers
      }

      // Move focus based on keypress
      if (key === "ArrowRight" && index < this.otpInputs.length - 1) {
        this.$refs.otpInput[index + 1].focus();
      } else if (key === "ArrowLeft" && index > 0) {
        this.$refs.otpInput[index - 1].focus();
      } else if (this.otpInputs[index] && index < this.otpInputs.length - 1) {
        // Auto-move to next input if a number is entered
        this.$refs.otpInput[index + 1].focus();
      }
    },
    validatePhoneNumber() {
      // Simple check to ensure the phone number does not start with a negative sign
      // and contains only digits. You can replace this with a more robust validation if needed.
      const isNegative = this.loginForm.phone.startsWith("-");
      const isNumeric = /^\d+$/.test(this.loginForm.phone);

      this.invalidPhoneNumber = isNegative || !isNumeric;

      // If you have a localization library like vue-i18n, you can use it to switch messages
      this.validationMessage = "ادخل رقم هاتف صحيح";
    },
    async handleLogin() {
      await CrudDataService.create(`login`, this.loginForm)
        .then((response) => {
          this.otpmessage = response.data.message;
          this.openotp = true;
          this.errorMessage = ''
          nextTick(() => {
            if (this.$refs.otpInput && this.$refs.otpInput[0]) {
              this.$refs.otpInput[0].focus();
            }
          });

        })
        .catch((error) => {
          this.errorMessage = error.data.message;
        });
    },
    async handleverify() {
      const otp = this.otpInputs.join('');
      await CrudDataService.create(`verify-otp`, {
        phone: this.loginForm.phone,
        otp: otp,
      })
        .then((response) => {
          this.$router.push({ name: 'home' })
          localStorage.setItem('authToken', response.data.data.token);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          this.errorMessage = error.data.message;
        });
    },
    signlink() {
      this.$router.push({ name: "SignUp" });
    },

  },
  mounted() {
    // Ensure first OTP input is focused on component mount
    nextTick(() => {
      if (this.$refs.otpInput && this.$refs.otpInput[0]) {
        this.$refs.otpInput[0].focus();
      }
    });
  },
  };
</script>
<style>
  .otp-field {
    margin-right: 5px;
    margin-left: 5px;
    max-width: 60px; /* Adjust width of each input field */
  }
  .otp-field .v-text-field .v-field--no-label input,.otp-field .v-text-field .v-field--active input {
    text-align: center;
}
.otp-field input {
  text-align: center !important;
}
[dir='ltr'] .bg-sec .v-img__img--contain {
    transform: rotateY(180deg);
}
.tel input {
  text-align: start !important;
}
.sideone .v-input--density-default .v-field--variant-outlined,
.sideone .v-input--density-default .v-field--single-line,
.sideone .v-input--density-default .v-field--no-label {
  background: #f0efff;
  color: #a7a3ff;
}
@media (max-width: 991.98px) {
  .sideone .imgsection {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .sideone .logimg {
    display: none;
  }
  .sideone .arrowimg {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .sideone .sec-login {
    width: 90% !important;
  }
  .sideone .bg-sec {
  height: 130vh;
    object-fit: cover !important;
  }
  
  .bg-sec .v-img__img--contain {
    object-fit: cover !important;
}
}
.v-overlay__scrim {
  background: rgba(187, 134, 252, 0.762);
}
</style>
